// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'navbar';

.body {
  background-color: #f8f9fa;
}

//IE 11 Fallback to generic theme
.main-disclaimer {
  background-color:#484848;
}

.l-extra-textT {
  background-color: #484848;
}

.footer {
  background-color: black;
}
.cookiebanner  {
  background-color: #484848;
}
.btn, .cookiebtn {
  color: white;
  background-color:black;
  border: 1px solid white;
}

.btn, .cookiebtn:focus, .cookiebtn:hover {
  background: var(--mob-back-highlight);
  color: var(--mob-font-highlight);
}
//END OF IE11 Fallback

@media (min-width: 768px) {
  .main-disclaimer {
    background-color: var(--land-background);
  }
  .l-extra-textT {
    background-color: var(--land-background);
  }
  .footer {
    background-color: var(--footer-color);
  }
  .btn, .cookiebanner  {
    background-color: var(--land-background);
  }
  .btn, .cookiebtn {
    color: var(--font-color);
    background-color: var(--back-color);
    border: 1px solid var(--border-color);
  }

  .cookiebtn:focus, .cookiebtn:hover {
    background: var(--back-highlight);
    color: var(--font-highlight);
  }

  .btn:focus,
  .btn:hover {
      background: var(--back-highlight);
      color: var(--font-highlight);
  }
}

a {
  color: black;
}

@media (min-width: 992px) {
  .intro .intro-img {
    width: 100%;
    float: right;
  }
  .intro .intro-text {
    left: 0;
    width: 40%;
    position: absolute;
    top: 0px;
    border-style: double;
    color: black;
  }
  .intro .intro-text .intro-button {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2rem;
  }
}

@media (min-width: 1200px) {
  .intro .intro-text {
    width: 45%;
  }
}

.main-disclaimer .main-disclaimer-inner {
  position: relative;
  background-color: rgb(255, 255, 255);
  width:80vw;
  border-radius: 25px !important;
  margin: auto;
}

.l-extra-textT .l-extra-text {
  position: relative;
  background-color: rgb(255, 255, 255);
  width:80vw;
  border-radius: 25px !important;
  margin: auto;
}

.landing-back {
  background: url(../../img/landing.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
}


.card{
    border-radius: 25px !important;
    border: 0px;
    box-shadow: 1px 4px 5px #888888;
}

@media(min-width: 318px) {
  .card{
    margin:auto;
  }
}
.card-img, .card-img-top {
  width: 50%;
  display: block !important;
  margin: 0 auto !important;
}

.card-title {
  margin-bottom: 0.75rem;
  text-align: center;
}

.card-body {
  padding: 0rem !important;
}
.card-text:last-child {
  text-align: center;
}

a.traffweb {
  height: 350px;
  border-radius: 25px !important;
  box-shadow: 1px 4px 5px #888888;
}

a.traffweb:hover,a.traffweb:focus  {
  height: 350px;
  background: rgb(230, 230, 230);
  transform: scale(1.05);
  border-radius: 25px !important;
  text-decoration: none;
}


// for Additional links page

.infoimg {
  max-width: 180px !important;
}

.client-logo {
    height: 70px;
    padding: 0;
}

.badge {
    margin:2px;
}

.listlogo {
    margin: 2px;
}


/*
* Main Landing Page
*/
.landinglist {
    background: url(../../img/clients-banner.png) no-repeat;
    margin-bottom: 5px;
    border-radius: 15px !important;
    box-shadow: 1px 2px 3px #888888;
}

.landinglist:hover {
  transform: scale(1.005);
}

/**
 * We may remove this
 */
.c-section {
  background: rgb(255, 255, 255, .8);
}


@media(max-width: 768px) {
  .listlogo {
    display: none;
  }

  .landinglist {
    background: white;
    margin-bottom: 5px;
    border-radius: 15px !important;
    box-shadow: 1px 2px 3px #888888;
  }
}

/**
 * Release Notes
 */

.release-notes-btn{
    background: none;
    border: none;
}

.release-notes-btn.active{
  background-color: var(--back-highlight) !important;
  font-weight: bold;
}

.release-notes-btn:hover {
  background-color: whitesmoke;
  font-weight: bold;
}